/**
 * マイページの定期券一覧のクエリパラメータコントロール用middleware
 */
export default defineNuxtRouteMiddleware((to, from) => {
  if (!to.query.page) {
    return navigateTo({
      path: '/mypage/pass',
      query: {
        page: 1,
      },
    });
  }
});
